module services {
    export module purchase {
        export class purchaseOrderTypeService implements interfaces.purchase.IPurchaseOrderTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "PurchaseOrderType/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
            
        }
    }
    angular.module("app").service("purchaseOrderTypeService", services.purchase.purchaseOrderTypeService);
}